@mixin breakpoint-w($width) {
    @media screen and (max-width: $width) {
      @content;
    }
  }

  @mixin breakpoint-w-min($width) {
    @media screen and (min-width: $width) {
      @content;
    }
  }

.blocker {
    @include breakpoint-w(500px) {
        padding: 0;
    }
    z-index: 99999998;
}

$accent: #3154C8;

.legal-page-modal {

    h2,
    h3,
    h4 {
        font-family: 'Montserrat', sans-serif;
    }
}

.legal-page-modal {
    font-family: Helvetica, Arial, sans-serif;
    max-width: 700px;
    display: none;
    vertical-align: middle;
    position: relative;
    z-index: 99999999;
    box-sizing: border-box;
    padding: 0;
    width: 90%;
    background: #fff;
    border-radius: 0;
    box-shadow: none;
    text-align: initial;
    position: relative;
    max-height: 70%;

    @include breakpoint-w(500px) {
        width: 100%;
        max-height: 100%;
        max-height: unset;
        min-height: 100vh;
        height: 100vh;
    }

    .close-icon {
        top: 32px;
        right: 32px;
        transition: 0.3s transform;
        z-index: 1;
        position: absolute;
        cursor: pointer;

        &:hover {
            transform: rotate(90deg);
        }

        @include breakpoint-w(500px) {
            width: 20px;
            height: 20px;
            top: 14px;
            right: 18px;
        }
    }


    .legal-page-title--wrapper {
        width: 100%;
        position: absolute;
        background-color: #ffffff;
        top: 0;
        left: 0;
        display: flex;
        padding: 20px 0;
        border-bottom: 1px solid #f2f2f2;

        @include breakpoint-w(500px) {
            padding: 15px 0;
        }

        .legal-page-title {
            margin: 0 auto !important;
            font-weight: 600;
            font-size: 24px;
            line-height: 48px;
            color: #333;

            @include breakpoint-w(500px) {
                font-size: 18px;
                line-height: 24px;
                margin: 0 auto 0 15px;
            }

        }
    }


    .legal-page-content--wrapper {
        max-height: calc(69vh - 139px);
        padding: 60px 24px 24px;
        margin-top: 48px;
        overflow: auto;
        @include breakpoint-w(500px) {
            margin-top: 37px;
            max-height: calc(100vh - 80px);
            padding: 25px 15px 15px;
        }

        .legal-page-content {

            h3 {
                font-weight: 600;
                font-size: 24px;
                line-height: 31px;
                margin-top: 15px;
                margin-bottom: 15px;
                color: #333;

                &:first-child {
                    margin-top: 0px;
                }

                @include breakpoint-w(500px) {
                    font-size: 16px;
                    line-height: 21px;
                    margin-top: 15px;
                    margin-bottom: 10px;

                    &:first-child {
                        margin-top: 0px;
                    }

                }
            }


            h4 {
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                margin-top: 15px;
                color: #333;

                @include breakpoint-w(500px) {
                    font-size: 14px;
                    line-height: 16px;
                    margin-top: 5px;

                }
            }


            p, ul, li, a {
                font-size: 16px;
                line-height: 26px;
                font-weight: 400;

                &:not(a){
                    color: #333;
                }
                @include breakpoint-w(500px) {
                    font-size: 12px;
                    line-height: 19px;
                }
            }



            a {
                color: $accent;
                transition: 0.3s color;
                font-weight: bold;
                &:hover {
                    color: $accent;
                }
            }

            p, ul, ol {
                margin-bottom: 15px;
                li {
                    margin-bottom: 7px;
                }
                @include breakpoint-w(500px) {
                    margin-bottom: 10px;
                    li {
                        margin-bottom: 5px;
                    }
                }
            }

            ul {
                list-style: none;
                padding-left: 15px;
                @include breakpoint-w(500px) {
                    padding-left: 7px;

                }

                li {
                    margin-bottom: 5px;
                }

                li:before {
                    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
                    color: $accent; /* Change the color */
                    font-weight: bold; /* If you want it to be bold */
                    display: inline-block; /* Needed to add space between the bullet and the text */
                    width: 20px; /* Also needed for space (tweak if needed) */
                }
            }

            ol {
                list-style-type: none;
                counter-reset: item;
              }

              ol > li {
                display: table;
                counter-increment: item;
                margin-bottom: 7px;
              }

              ol > li:before {
                content: counters(item, ".") ". ";
                display: table-cell;
                padding-right: 7px;
                font-weight: bold;

              }

              li ol > li {
                margin: 0;
              }

              li ol > li:before {
                content: counters(item, ".") ". ";
              }
        }
    }

}
